/* index.css */

.feed {
  margin: 0 auto;
  width: 100%;
  padding: 20px;
}

.container {
  max-width: 100%;
}
