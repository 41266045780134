@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

#root {
  padding-top: 50px;
}

section.main {
  padding-top: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

.black {
  color: #000;
}

.dark-purple {
  color: #9e9fca;
}

.landing-text {
  margin-bottom: 20px;
}

.landing-text h3 {
  margin-bottom: 0rem;
  font-weight: bold;
}

.signup-btn {
  background-color: #dbd3ff;
  color: #000;
  border-radius: 100px;
  border: none;
  padding: 12px 40px;
  font-weight: bold;
}

@media (min-width: 80.0rem) { /* Mobile devices */
  section.main.logged-in {
    padding-top: 0;
    padding-left:250px;
    padding-right: 250px;
  }
}