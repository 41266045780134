.post {
  text-align: left;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  width: 100%;
}

.post-header {
  display: flex;
  align-items: center;
}

.post-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.post-user-info h4 {
  margin: 0;
  font-size: 16px;
}

.post-user-info p {
  margin: 0;
  font-size: 12px;
  color: #888;
}

.post-body {
  margin-top: 16px;
}

.post-body p {
  margin-bottom: 5px;
}

.post-title {
  font-size: 18px;
  margin: 0 0 8px;
}

.post-image {
  width: 100%;
  max-height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 8px;
  border: 2px solid #f7f6fd;
}

.post-link {
  color: #007bff;
  text-decoration: none;
  display: block;
  margin-bottom: 8px;
}

a.post-link {
  background-color: #9b4dca;
  color: #fff;
  padding: 0 5px;
  border-radius: 10px;
  display: inline-block;
  font-weight: bold;
}

.post-description {
  font-size: 14px;
  color: #555;
}

.post-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.post-save-button, .post-share-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.post-save-button:hover, .post-share-button:hover {
  background-color: #0056b3;
}

.post-saved-by {
  font-size: 12px;
  color: #888;
}
