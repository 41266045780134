.back-arrow {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 10%; /* Adjust as needed */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.back-arrow:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Hover effect */
}

.back-arrow svg {
  color: #000; /* Set the arrow color */
}
