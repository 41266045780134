/* UserProfile.css */

/* General Container Styles */
.gradient-custom-2 {
  background: linear-gradient(45deg, #ff6f61, #ff8e53);
  color: white;
  padding: 40px 0;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

/* Profile Header */
.text-center {
  text-align: center !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.img-thumbnail {
  padding: .25rem;
  background-color: #f7f7f7;
  border: 1px solid #dee2e6;
}

h3.font-weight-bold {
  font-weight: 700 !important;
}

.text-muted {
  color: #6c757d !important;
}

.btn-rounded {
  border-radius: 50px;
  padding: .375rem 1.5rem;
}

/* Stats Section */
.text-black {
  color: #000 !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.small.text-muted {
  font-size: .875rem;
  color: #6c757d;
}

/* Tabs */

.center-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-tabs .nav-tabs .nav-link i {
  font-size: 1.5em;
}

.center-tabs .nav-tabs .nav-link.active {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.55);
}

/* Link Sections */
h4.font-weight-bold {
  font-weight: 700 !important;
  margin-bottom: 1.5rem;
}

.overflow-auto {
  overflow: auto;
  white-space: nowrap;
}

.overflow-auto .link-card {
  display: inline-block;
  margin-right: 15px;
  width: 150px;
}

.overflow-auto .link-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.overflow-auto .link-title {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin-top: .5rem;
  text-align: center;
}

.card {
  --mdb-card-box-shadow: 0;
}

/* Utility Classes */
.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.slick-prev:before, .slick-next:before {
  color: #000 !important;
}

.slick-prev {
  left: -55px !important;
}

.slick-next {
  right: -55px !important;
}

.card-header-image {
  height: 7em;
  width: 90%;
  object-fit: scale-down;
  box-shadow: 2px 2px 2px black;
}

.post-card-title {
  font-size: .8em;
  font-weight: bold;
  margin-bottom: 0;
}

.post-card-caption {
  font-size: .8em;
}

@media (max-width: 768px) {
  /* Make adjustments for mobile view */
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .img-thumbnail {
    width: 100px;
    height: 100px;
  }

  h3.font-weight-bold {
    font-size: 1.5rem;
  }

  .btn-rounded {
    padding: .375rem 1rem;
  }

  .overflow-auto .link-card {
    width: 120px;
  }
}
