.grid-container {
  display: none;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 60px;
  padding: 10px;
  width: 100%; /* Ensure it spans the full width of its parent */
  min-width: 1024px;
  max-height: 50%;
  position: fixed;
  bottom: 20px;
  left: 50%; /* Starts from the middle of the viewport */
  transform: translateX(-50%); /* Centers it horizontally */
  margin: 0 auto;
  z-index: -1;
}

.grid-item {
  display: flex;
  align-items: flex-end; /* Align items to the bottom */
  justify-content: center; /* Center the image horizontally */
  overflow: hidden; /* Hide any overflow */
  position: relative;
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 15px;
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, 
  from(rgba(0,0,0,0)), to(rgba(0,0,0,0.7)));
}

@media (min-width: 40.0rem) { /* Mobile devices */
  .grid-container {
    display: grid;
  }
}
