/* src/Header.css */
* {
  margin: 0;
  padding: 0;
}

header {
  border-radius: 100px;
  text-align: center;
  width: 80%;
  z-index: 999;
  padding: .4rem;
  position: fixed;
  left: 10%;
  top: 20px;
  background: #f7f6fd;
  color: #000;
}

header .signup-btn {
  float: right;
  position: relative;
}

header input.menu-toggle {
  display: none;
}

nav {
  position: absolute;
  text-align: center;
  top: 100%;
  left: 5%;
  background-color: #f7f6fd;
  opacity: 99%;
  width: 90%;
  transform: scale(1, 0);
  transition: transform 400ms ease-in-out;
  transform-origin: top;
}

nav ul {
  margin: 0;
  margin-top: 14%;
  padding: 0;
  height: 90vh;
}

nav li {
  margin-left: 1rem;
  height: 10%;
  list-style: none;
}

nav a {
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 200ms ease-in 100ms;
}

.nav-heading {
  font-size: 3rem;
  font-weight: bold;
  float: left;
  position: relative;
  left: 5%;
}

.nav-heading a {
  color: #000;
}

.nav-brand img {
  height: 30px;
  position: relative;
  top: -3px;
  margin-right: 10px;
}

.menu-toggle:checked ~ nav {
  transform: scale(1, 1);
}

.menu-toggle:checked ~ nav a {
  opacity: 1;
}

.menu-nav-toggle {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 2rem;
  cursor: pointer;
}

.menu-nav-toggle span,
.menu-nav-toggle span:before,
.menu-nav-toggle span:after {
  display: block;
  background: #333;
  width: 2rem;
  border-radius: 0.125rem;
  position: relative;
  height: 0.25rem;
}

.menu-nav-toggle span:before,
.menu-nav-toggle span:after {
  content: '';
  position: absolute;
}

.menu-nav-toggle span:before {
  top: 0.8rem;
}

.menu-nav-toggle span:after {
  bottom: 0.8rem;
}

@media (min-width: 80.0rem) { /* Mobile devices */
  header.logged-in {
    /* display: none; */
  }
}